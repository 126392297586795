import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import Seo from '../../components/seo'

const Works = ({ data }) => {
  console.log(data)
  const projects = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <Seo title="İşlerimiz" />
      <div className="container">
        <div className="about-hero">
          <StaticImage
            src="../../images/logo.svg"
            alt="BM Creative Works"
            className="menu-logo"
          />
        </div>

        <div className="works-head">
          <h2>
            <span>Önemli olan sadece ne yaptığımız değil.</span>
          </h2>
          <h2>Ayrıca sizin için ne yapacağımızdır.</h2>
        </div>
      </div>

      <div className="works-page">
        {projects.map((project) => (
          <Link to={'/works/' + project.frontmatter.slug} key={project.id}>
            <div className="work work1">
              <div className="head">
                <h3>{project.frontmatter.title}</h3>
                <h4>{project.frontmatter.type}</h4>
              </div>
              <GatsbyImage
                key={project.id}
                image={
                  project.frontmatter.featuredImg.childImageSharp
                    .gatsbyImageData
                }
                alt={project.frontmatter.title}
              />
            </div>
          </Link>
        ))}
      </div>
    </Layout>
  )
}

export default Works

export const query = graphql`
  query WorksPage {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        html
        frontmatter {
          date
          slug
          title
          type
          featuredImg {
            childImageSharp {
              gatsbyImageData(
                formats: AUTO
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
        }
        id
      }
    }
  }
`
